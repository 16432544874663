.navbar-dropdown-item {
  display: flex;
  align-items: center;

  svg {
    fill: currentColor;
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5rem;
  }
}
