.main-sidebar {
  transition-property: transform, width, visibility;
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
  width: 22vw;
  &--hidden {
    transform: translateX(-1000px);
    width: 0;
    visibility: hidden;
  }

  &__nav-item {
    &--active {
      background-color: $gray-100;
    }
  }

  &__user-dropdown {
    background-color: $gray-100;
    height: 5rem;
    z-index: 1;
    position: relative;
    &:hover,
    &:active {
      background-color: $gray-100 !important;
      border-color: transparent !important;
    }

    svg {
      width: 1.75rem;
      height: 1.75rem;
    }

    &--active {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &-avatar {
      svg,
      img {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    &-icon {
      border: none !important;
      outline: none !important;
      svg {
        width: 1.5rem;
        height: 1.5rem;
        border: none !important;
        outline: none !important;
        &:focus {
          border: none !important;
          outline: none;
        }
      }

      &:active {
        border: none;
      }
    }

    &-items {
      background-color: $gray-100;
      transition-property: transform, visibility, height;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
      position: relative;
      &--hidden {
        transform: translateY(-2rem);
        visibility: hidden;
        height: 0;
      }
    }
  }
}
