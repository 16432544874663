.bom-history {
  &__toolbar-filter-button {
    color: $light-4;

    &--active {
      color: $primary;
    }
  }

  &__sort-dropdown-btn {
    border: none !important;
    outline: none !important;

    &:active {
      border: none !important;
      outline: none !important;
    }
  }

  &__sort-dropdown {
    top: 2rem;
    left: 0;
    box-shadow: -1px 23px 29px -7px rgba(0, 0, 0, 0.26);
    -webkit-box-shadow: -1px 23px 29px -7px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: -1px 23px 29px -7px rgba(0, 0, 0, 0.26);
    width: 8rem;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-1rem);
    transition-property: transform, visibility, opacity;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;

    &--visible {
      visibility: visible;
      transform: translateY(0);
      opacity: 1;
    }

    &-item {
      font-size: 0.825rem;
      color: $primary;
      &:not(:last-child) {
        margin-bottom: 0.25rem;
      }
    }
  }
}
