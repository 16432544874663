.navbar-avatar-btn {
  img,
  svg {
    width: 2rem;
    height: 2rem;
  }
}

.main-header {
  transition-property: transform, height;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;

  &--hidden {
    transform: translateY(-72px);
    height: 0;
  }
}
