.registration-carousel-frame {
  height: 100% !important;
}

.slider-container {
  height: 100% !important;

  .slider-control-bottomcenter {
    margin-bottom: 1.25rem;
    .paging-item {
      fill: $light-1 !important;

      &.active {
        fill: $light-2 !important;
      }
    }
  }

  .slider-control-centerleft,
  .slider-control-centerright {
    display: none !important;
  }
}
