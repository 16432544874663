@import "../../node_modules/bootstrap/scss/functions";

// Import my variables
@import "vars";

// Bootstrap stuff
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/accordion";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/offcanvas";

// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities API
@import "../../node_modules/bootstrap/scss/utilities/api";

@import "./utils";
@import "./components/progress-bar";
@import "./components/forms";
@import "./components/main-navbar";
@import "./components/main-sidebar";
@import "./components/navbar-dropdowns";
@import "./components/bom-history";
@import "./components/bom-card";
@import "./components/usmca-certificate";
@import "./components/bom-wizard";
@import "./components/bom-next-steps-card";
@import "./components/registration-carousel";
@import "./components/csv-import";
@import "./components/buttons";
@import "./components/tables";

.icon {
  width: 1rem;
  height: 1rem;
}

.icon--btn {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.icon-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;

  svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
  }
}

.row-v-middle {
  th,
  td {
    vertical-align: middle;
  }
}

.modal-content {
  box-shadow: -1px 23px 29px -7px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: -1px 23px 29px -7px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: -1px 23px 29px -7px rgba(0, 0, 0, 0.26);
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: $primary;

  &:focus {
    box-shadow: 0 0 0 3px rgba($primary, 0.5);
  }
}
