.bom-card {
  height: 12rem;

  &--loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--grid {
    display: grid;
    grid-template-rows: 1fr 1fr 2fr 1fr;
  }

  &__settings-btn {
    border: none !important;
    &:active {
      border: none !important;
      outline: none;
    }
    &-dropdown {
      right: 0.825rem;
      width: 14rem;
      transition-property: visibility, opacity, transform;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
      visibility: hidden;
      opacity: 0;
      transform: translateY(-1rem);

      &--active {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
      }

      &-item {
        color: $primary;
        font-size: 0.825rem;
      }
    }
  }
}
