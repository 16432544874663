.usmca-certificate {
  &__qualified-bom-card {
    height: 175px;
  }

  &__producer-type-card {
    background-color: $light-2;
    transition-property: color, background-color;
    transition-timing-function: ease-in;
    transition-duration: 0.3s;
    &--selected {
      background-color: $primary;

      &:hover {
        background-color: $secondary !important;
      }
    }

    &:hover {
      background-color: $light-3;
    }
  }

  &__card-settings-dropdown-toggle {
    border: none !important;
    outline: none !important;

    &:active {
      border: none !important;
      outline: none !important;
    }
  }

  &__card-settings-dropdown {
    box-shadow: -1px 23px 29px -7px rgba(0, 0, 0, 0.26);
    -webkit-box-shadow: -1px 23px 29px -7px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: -1px 23px 29px -7px rgba(0, 0, 0, 0.26);
    transition-property: opacity, transform, visibility;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;

    &--hidden {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-1rem);
    }
    &-item {
      font-size: 0.825rem;
      border: none !important;
      outline: none !important;

      &:active {
        border: none !important;
        outline: none !important;
      }
    }
  }

  &__sort-by-dropdown-toggle {
  }

  &__sort-by-dropdown-menu {
    background-color: white;
    box-shadow: -1px 23px 29px -7px rgba(0, 0, 0, 0.26);
    -webkit-box-shadow: -1px 23px 29px -7px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: -1px 23px 29px -7px rgba(0, 0, 0, 0.26);
    top: 1.75rem;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-1rem);
    transition-property: opacity, visibility, transform;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;

    &--shown {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }

    &-item {
      border: none !important;
      outline: none !important;

      &:active {
        border: none !important;
        outline: none !important;
      }
    }
  }

  &__detail-data-card {
    &-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(2rem, 1fr));
      gap: 1rem;

      &-item {
        background-color: white;
      }
    }
  }
}
