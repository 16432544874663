.sort-by-btn {
  &:hover,
  &:active {
    background-color: transparent !important;
  }

  &__dropdown {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-1rem);
    transition: all 0.3s ease-in;
    &--show {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
}
